<template>
  <main>
    <section class="container py-landing text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-9">
          <h1 class=" font-weight-bold color-white">CRISIS Security Bug Bounty </h1>

        </div>
      </div>
    </section>
    <section class="general bg-color-blue-nav color-white">
      <div class="container pt-4">
        <div class="general-wrapper">
          <div class="row justify-content-center">
            <div class="col-12 font-size-15">
              <p>Software security researchers are increasingly collaborating with internet corporations to discover security flaws. Our bounty program pays tribute to great researchers by offering prizes of up to 1000$ for significant vulnerabilities.</p>
              <p>If you discover a vulnerability, report it <a href="mailto:bounty@crisi5.com" target="_blanck">here</a>.</p>
              <p>Our rules, scope, aims, and FAQ sections contain important information.</p>
              <strong>Have fun hacking!</strong>
              <h2 class="my-4">Eligible Websites & Services</h2>
              <h4 class="my-3"><strong>CRISIS User</strong></h4>
              <ul class="m-4">
                <li>Crisi5.com</li>
                <li>Ctf.crisi5.com</li>
                <li>Api.crisi5.com</li>
              </ul>
              <h4 class="my-3"><strong>CRISIS KoTH</strong></h4>
              <ul class="m-4">
                <li>Ctf.crisi5.com/koth</li>
                <li>Koth.crisi5.com</li>
              </ul>
              <h4 class="my-3"><strong>CRISIS VPN</strong></h4>
              <ul class="m-4">
                <li>13.37.231.103</li>
              </ul>
              <h2 class="my-3">Out of Scope</h2>
              <ul class="m-xl-4">
                <li>Spam or social engineering techniques.</li>
                <li>Denial-of-service attacks.</li>
                <li>Content injection. Posting content on CRISIS is a core feature, and content injection (also "content spoofing" or "HTML injection") is out of scope unless you can clearly demonstrate a significant risk.</li>
                <li>CSRF for non-significant actions</li>
                <li>CORS misconfigurations when the Credentials header is not set</li>
                <li>Missing HTTP security Headers that do not directly lead to a vulnerability, such as:</li>
                <ul class="my-3">
                  <li>Content-Security-Policy</li>
                  <li>Strict Transport Security</li>
                  <li>X-Content-Type-Options</li>
                  <li>X-XSS-Protection</li>
                </ul>
                <li>X-Frame-Options (unless there is a well-defined risk)</li>
                <li>X-Download-Options</li>
                <li>X-XSS-Protection</li>
                <li>Feature/Permissions Policy</li>
                <li>Missing best practices in SSL/TLS configuration</li>
                <li>Missing best practices in Content Security Policy</li>
                <li>Missing email best practices (Invalid, incomplete, or missing SPF/DKIM/DMARC records, etc.)</li>
                <li>Missing cookie flags on cookies that do not hold session or other sensitive information</li>
                <li>Information Disclosure – default exposed config files with no sensitive data</li>
                <li>Open redirect vulnerabilities that do not demonstrate additional security impact</li>
                <li>Content spoofing and text injection issues without showing an attack vector or being able to modify HTML/CSS</li>
                <li>Host header Injection with no demonstrable impact</li>
                <li>Vulnerabilities reported shortly after their public release</li>
                <li>Vulnerability reports from automated tools without validation</li>
                <li>Denial of Service and Social Engineering attacks</li>
                <li>Attacks requiring MITM or physical access to a user's device</li>
              </ul>
              <h2 class="my-3">Reporting your vulnerability</h2>
              <p>Submissions must include written instructions for reproducing the vulnerability. Submissions without clear reproduction steps or which only include reproduction steps in video form may be ineligible for a reward.</p>
              <p>During an investigation, it may take time to resolve the issue you have reported. We ask that you refrain from publicly disclosing details regarding an issue you’ve reported until the fix has been publicly made available.</p>
              <h2 class="my-3">Receiving your award</h2>
              <ul class="m-4">
                <li>When duplicates occur, we only award the first report that was received (if it can be fully reproduced).</li>
                <li>You are free to publish write-ups about your vulnerability and CRISIS will not limit what you write. We may pay out your reward before the vulnerability is patched so we may ask that you delay publishing to keep other CRISIS users safe.</li>
                <li>You will receive you reward via WIRE transfer or in cryptocurrencies.</li>
              </ul>
              <h5 class="my-3">
                Start you journey now, and report to us by email  <a href="mailto:bounty@crisi5.com" target="_blank" >
                bounty@crisi5.com.
              </a>

              </h5>
            </div>


            <a href="mailto:bounty@crisi5.com" target="_blank" class="btn btn-dark btn-lg mx-auto mb-4" >
              <i class="fa fa-bug mr-1" aria-hidden="true"></i> {{ $t('competition.report') }}
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Bounty",
  mounted () {
    window.scrollTo(0, 0)
  },
  data(){
    return{
    }
  }
}
</script>

<style scoped>
.py-landing {
  padding-top: 5rem;
}
section.general .general-wrapper {
  max-width: 43.5rem;
  margin: 0 auto;
}
p {
  color: #a4b1cd;
  line-height: 30px;
}
li{
  margin-bottom: 8px;
}

.btn-lg{
  padding: 0.7rem 2rem;
  font-size: 1.1rem;
}
</style>